import { Observable, from, bufferCount, concatMap, reduce } from 'rxjs';

export function createChunkedRequests<Item, Response>(
  items: Item[],
  chunkSize: number,
  requestFactory: (items: Item[]) => Observable<Response>,
): Observable<Response[]> {
  return from(items).pipe(
    bufferCount(chunkSize),
    concatMap((chunk) => requestFactory(chunk)),
    reduce((acc, res) => acc.concat(res), new Array<Response>()),
  );
}
